import React, {useState} from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import {FaArrowCircleUp} from 'react-icons/fa';
import "../utils/carousel-with-custom-dots.css";

const ScrollToTop = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 700){
	setVisible(true)
	}
	else if (scrolled <= 700){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
	<ArrowDropUpIcon className="toTop" onClick={scrollToTop}
	style={{display: visible ? 'inline' : 'none'}} />
);
}

export default ScrollToTop;
