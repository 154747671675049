import React, {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
    Button,
    TextField
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import colors from "../utils/myColors";

const serviceList = [
    { label: 'Finance & Accounting'},
    { label: 'Payroll Services'},
    { label: 'Financial Management'},
    { label: 'Internal Controls'},
    { label: 'Statutory Compliance'},
    { label: "Business Structuring"}
  ];

const ContactForm = (props) => {
   const [userName, setUserName] = useState('');
   const [OrgName, setOrgName] = useState('');
   const [Mobile, setMobile] = useState('');
   const [Emailid, setEmailid] = useState(''); 
   const [Services, setServices] = useState(''); 
   const [Comments, setComments] = useState('');
   const [fullWidth, setFullWidth] = React.useState(true);

   const handleClose = () => {
        props.setOpen(false); 
   };
  
   const submit = async (e) => {
        e.preventDefault();
        var name = userName ? userName : "";
        var organization_name = OrgName ? OrgName : "";
        var mobile = Mobile ? Mobile : "";
        var email = Emailid ? Emailid : "";
        var services = Services.label ? Services.label : "";
        var comments = Comments ? Comments : "";

        const update_data = {
            name,
            organization_name,
            mobile,
            email,
            services,
            comments
        };

        try {
            // setSpinnerVisibility(true);
            const { data } = await axios.post(`https://virbusser.com/mail.php`,update_data,
                {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                }
            );
            //console.log(data.Status, " datares");
            //process.exit();
            //showToaster(EToasterVariants.success, "Upload data is success");
            if(data.Status === 1){
                props.setOpen(false);
            }
            
            // setSpinnerVisibility(false);
        } catch (error) {
            //showToaster(EToasterVariants.error, "Unable to upload the document");
        }
    }

    return (
        <Dialog open={props.openDialog} onClose={handleClose} fullWidth={fullWidth}>
            <DialogTitle sx={{ color: colors.logoGreen }}>Get Started!!!</DialogTitle>
               
                <form onSubmit={submit}>
                    <DialogContent>
                    <div className="mx-auto">
                        <div className="columns-12 mb-2 form-floating">
                            <TextField label="Name" size="small" fullWidth value={userName} onChange={e => setUserName(e.target.value)} required/>
                        </div>
                        <div className="columns-12 mb-2 form-floating">
                            <TextField label="Organization Name" size="small" fullWidth value={OrgName}  onChange={e => setOrgName(e.target.value)} required/>
                        </div>
                        <div className="columns-12 mb-2 form-floating">
                            <TextField label="Mobile" size="small" fullWidth value={Mobile}  onChange={e => setMobile(e.target.value)} required/>
                        </div>
                        <div className="columns-12 mb-2 form-floating">
                            <TextField label="Email ID" size="small" fullWidth value={Emailid}  onChange={e => setEmailid(e.target.value)} required/>
                        </div>
                        <div className="columns-12 mb-1 form-floating">
                            <Autocomplete
                                id="feedbackstatus"
                                options={serviceList}
                                value={Services}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Services you are looking for?"
                                    variant="outlined"
                                    required
                                />
                                )}
                                getOptionLabel={(option) => option.label ? option.label : ''}
                                style={{ width: "100%" }}
                                onChange={(_event, newInputValue) => {
                                    setServices(newInputValue);
                                }}
                            />
                        </div>
                        <div className="columns-12 form-floating">
                            <TextField label="Comments" size="small" fullWidth rows={4} multiline value={Comments}  onChange={e => setComments(e.target.value)} required/>
                        </div>
                    </div>
                    </DialogContent>


                    <div className="mb-2 pe-2">
                        <DialogActions>
                            <Button className="view-status-cancel btn-border-green" variant="outlined" onClick={handleClose}>Close</Button>
                            <Button className="view-status btn-green" variant="contained" type="submit">Submit</Button>
                        </DialogActions>
                    </div>



                </form>
        </Dialog>
    );
};

export default ContactForm;